import {
	Button,
	Errors,
	Field,
	FieldDescription,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	Select,
	TextField,
	TextLink,
	Container,
	Breadcrumbs,
	BreadcrumbItem,
	Section,
	Page,
} from '@troon/ui';
import { cache, createAsync, useLocation, useSubmission } from '@solidjs/router';
import { createSignal, For, Match, Show, Suspense, Switch } from 'solid-js';
import dayjs from 'dayjs';
import { Formspree, formspreeAction } from '@troon/forms';
import { Icon } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { useUser } from '../../../providers/user';
import { getConfigValue } from '../../../modules/config';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { createDollarFormatter } from '../../../modules/number-formatting';
import type { Data } from './api/(api)';

export default function RequestATeeTime() {
	const user = useUser();
	const [guests, setGuests] = createSignal(0);
	const [members, setMembers] = createSignal(0);
	const [desiredClub, setDesiredClub] = createSignal<string | null>(null);
	const submission = useSubmission(requestTeeTime);
	const loc = useLocation();

	const dataTable = createAsync(() => getDataTable(desiredClub()));
	const dollarFormatter = createDollarFormatter();

	return (
		<>
			<Container>
				<Title>Request a Tee Time | Troon Privé Member Programs | Troon</Title>
				<div class="pt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
						<BreadcrumbItem href="/member-programs/private-club-golf">Private Club Golf</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Request a tee time</BreadcrumbItem>
					</Breadcrumbs>
				</div>

				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Icon name="golf-cart-custom" class="size-24" />
								<Heading as="h2">Request a tee time</Heading>

								<p>
									Please note that only qualified private club members may request tee times at participating private
									clubs within Troon Privé Privileges. If you have any questions about the program or are experiencing
									issues accessing these benefits, please contact your home club staff.
								</p>

								<p>
									For Visa Infinite Members seeking to request a tee time, please utilize the designated site{' '}
									<TextLink href="https://troongolfrewards.com/verify/visa-infinite?locale=us">US Cardholders</TextLink>{' '}
									or{' '}
									<TextLink href="https://offer.troongolfrewards.com/visainfiniteprivilege?language=en">
										Canada Cardholders
									</TextLink>
									.
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check-custom" class="text-8xl" />
										<Heading as="h1" size="h2">
											Request received!
										</Heading>
										<p class="text-lg">
											Thank you for submitting your request. We have received your Troon Privé member request to reserve
											a tee time at one of our participating private clubs. Your desired destination club will be in
											touch to confirm your reservation.
										</p>
									</div>
								</Match>
								<Match when>
									<Formspree action={requestTeeTime} class="flex flex-col gap-6">
										<div class="flex w-full flex-wrap gap-6">
											<TextField name="First name" required class="grow">
												<Label>First name</Label>
												<Input autocomplete="given-name" value={user()?.me.firstName} />
											</TextField>

											<TextField name="Last name" required class="grow">
												<Label>Last name</Label>
												<Input autocomplete="family-name" value={user()?.me.lastName} />
											</TextField>
										</div>

										<TextField name="Email" required>
											<Label>Email address</Label>
											<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
										</TextField>

										<TextField name="Phone" required>
											<Label>Phone number</Label>
											<Input autocomplete="tel" inputMode="tel" />
										</TextField>

										<Field name="Home club" required>
											<Label>Your home club</Label>
											<Select>
												<Option disabled selected class="hidden" />
												<For each={homeClubs}>{(club) => <Option>{club}</Option>}</For>
											</Select>
											<FieldErrors />
										</Field>

										<TextField name="Home club membership #" required>
											<Label>Home club membership #</Label>
											<Input />
										</TextField>

										<Field name="Desired club" required>
											<Label>Club you desire to play</Label>
											<Select onChange={(e) => setDesiredClub(e.target.value)}>
												<Option disabled selected class="hidden" />
												<For each={playClubs}>{(club) => <Option>{club}</Option>}</For>
											</Select>
											<FieldErrors />
										</Field>

										<Suspense>
											<Show when={dataTable()}>
												{(data) => (
													<div class="flex flex-col gap-4 border-b border-neutral pb-4">
														<table class="w-full">
															<thead>
																<tr>
																	<th class="rounded-tl-md border border-neutral bg-brand-700 px-3 py-2 text-white">
																		Schedule
																	</th>
																	<th class="border border-neutral bg-brand-700 px-3 py-2 text-white">Days</th>
																	<th class="border border-neutral bg-brand-700 px-3 py-2 text-white">Rate</th>
																	<th class="rounded-tr-md border border-neutral bg-brand-700 px-3 py-2 text-white">
																		Guest fee
																	</th>
																</tr>
															</thead>
															<tbody>
																<For each={Object.entries(data().season)}>
																	{([season, d]) => (
																		<tr>
																			<td class="border border-neutral bg-white px-3 py-2">{d.months}</td>
																			<td class="border border-neutral bg-white px-3 py-2">{data().days}</td>
																			<td class="border border-neutral bg-white px-3 py-2">
																				<Show when={season === 'high'} fallback="$55">
																					$80
																				</Show>
																			</td>
																			<td class="border border-neutral bg-white px-3 py-2">
																				{typeof d.guestFee === 'string'
																					? d.guestFee
																					: dollarFormatter().format(d.guestFee)}
																			</td>
																		</tr>
																	)}
																</For>
															</tbody>
														</table>
														<For each={data().restrictions?.split(/\n+/g)}>{(restriction) => <p>{restriction}</p>}</For>
													</div>
												)}
											</Show>
										</Suspense>

										<TextField name="Date and time" required>
											<Label>Date you desire to play</Label>
											<Input type="datetime-local" min={dayjs().format('YYYY-MM-DDThh:mm')} />
										</TextField>

										<RadioGroup name="Number of members" onSelect={(value) => setMembers(parseInt(value, 10) - 1)}>
											<Label>Total number of members</Label>
											<RadioBar>
												<RadioBarButton value="1" checked>
													<Label>1</Label>
												</RadioBarButton>
												<RadioBarButton value="2">
													<Label>2</Label>
												</RadioBarButton>
												<RadioBarButton value="3">
													<Label>3</Label>
												</RadioBarButton>
												<RadioBarButton value="4">
													<Label>4</Label>
												</RadioBarButton>
											</RadioBar>
											<FieldDescription>
												Select the total number of members <em>including yourself</em>.
											</FieldDescription>
										</RadioGroup>

										<Show when={members() > 0}>
											<fieldset class="mb-8 flex flex-col gap-4 border-b border-neutral pb-8">
												<legend class="text-lg font-semibold">
													Please enter the first and last name of each of your accompanying members
												</legend>
												<For each={[...Array(members()).keys()]}>
													{(_, i) => (
														<TextField name={`Accompanying guest ${i() + 1}`}>
															<Label>Accompanying member {i() + 1}</Label>
															<Input />
														</TextField>
													)}
												</For>
											</fieldset>
										</Show>

										<RadioGroup name="Number of guests" onSelect={(value) => setGuests(parseInt(value, 10))}>
											<Label>Total number of guests</Label>
											<RadioBar>
												<RadioBarButton value="0" checked>
													<Label>None</Label>
												</RadioBarButton>
												<RadioBarButton value="1">
													<Label>1</Label>
												</RadioBarButton>
												<RadioBarButton value="2">
													<Label>2</Label>
												</RadioBarButton>
												<RadioBarButton value="3">
													<Label>3</Label>
												</RadioBarButton>
											</RadioBar>
											<FieldDescription>Select the total number of non-member guests.</FieldDescription>
										</RadioGroup>

										<Show when={guests() > 0}>
											<fieldset class="mb-8 flex flex-col gap-4 border-b border-neutral pb-8">
												<legend class="text-lg font-semibold">
													Please enter the first and last name of each of your accompanying guests
												</legend>
												<For each={[...Array(guests()).keys()]}>
													{(_, i) => (
														<TextField name={`Accompanying guest ${i() + 1}`}>
															<Label>Accompanying guest {i() + 1}</Label>
															<Input />
														</TextField>
													)}
												</For>
											</fieldset>
										</Show>

										<TextField name="Additional comments">
											<Label>Additional comments</Label>
											<Input multiline />
										</TextField>

										<Errors />

										<Button class="w-fit" type="submit">
											Submit
										</Button>
									</Formspree>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const requestTeeTime = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'prive-tee-times');

const getDataTable = cache(async (courseName?: string | null) => {
	if (!courseName) {
		return null;
	}
	const params = new URLSearchParams({ courseName });
	const res = await fetch(`https://${getConfigValue('HOST')}/member-programs/private-club-golf/api?${params}`);
	if (!res.ok) {
		return null;
	}
	return res.json() as Promise<Data>;
}, 'prive-course-data-table');

const homeClubs = [
	'Alpine Country Club (RI)',
	'Alpine Country Club (UT)',
	'Arabian Ranches',
	'Audubon Country Club',
	'Austin Country Club',
	'Bahia Beach Resort & Golf Club',
	'BallenIsles Country Club',
	'Bay Creek',
	'Bayside Resort Golf Club',
	'Beechmont Country Club',
	'Belfair',
	'Blackstone Country Club',
	'Bonita National Golf & Country Club',
	'Bookcliff Country Club',
	'Boulders Resort & Spa',
	'Boulder Ridge Golf Club',
	'Broken Top Club',
	'Calvert Crossing Golf Club',
	'Canebrake Country Club',
	'Capital Canyon Club',
	'Centurion Club',
	'Champion Hills',
	'Chateau Golf & Country Club',
	'Chateau Elan Winery and Resort',
	'Cimarron Hills Golf & Country Club',
	'Colonial Country Club (FL)',
	'Columbia Country Club',
	'Columbus Country Club',
	'Comanche Trace',
	'Contra Costa Country Club',
	'Country Club of Colorado',
	'Crow Canyon Country Club',
	'Crystal Lake Country Club',
	'Deering Bay Yacht & Country Club',
	'Dunes of Naples',
	'Eau Claire Golf & Country Club',
	'Echo Valley Country Club',
	'El Macero Country Club',
	'Entrada at Snow Canyon',
	'Esplanade Golf & Country Club',
	'Esplanade Golf & Country Club Lakewood Ranch',
	'Fairbanks Ranch Country Club',
	'Fairwood Golf & Country Club',
	'Fargo Country Club',
	"Fiddler's Creek",
	'Fieldstone Golf Club',
	'Flagstaff Ranch Golf Club',
	'Gadsden Country Club',
	'Hammock Beach',
	'Hunting Creek Country Club',
	'John Newcombe Country Club',
	'Kankakee Country Club',
	'Kemper Lakes Golf Club',
	'Knollwood Country Club (MI)',
	'Lake Valley Golf Club',
	'La Paloma Country Club',
	'Le Triomphe Golf & Country Club',
	'Links at Perry Cabin',
	'Lomas Santa Fe Country Club',
	'Manchester Country Club',
	'Mauna Lani',
	'Marin Country Club',
	'Mill Creek Country Club',
	'Naperville Country Club',
	'Newport Beach Tennis Center',
	'NorthRiver Yacht Club',
	'Ocean Club at Hutchinson Island',
	'Ocean Edge Resort & Club',
	'Oldfield Club',
	'Old Overton Club',
	'Oneida Golf & Country Club',
	'Parkland Golf & Country Club',
	'Pelican Lakes Resort and Golf',
	'PGA National Resort',
	'PGA National Czech Republic',
	'Phoenix Country Club',
	'Pine Canyon Club',
	'Pine Tree Country Club',
	'Pinetop Country Club',
	'Polo Tennis and Fitness Club',
	'Pronghorn',
	'RainDance National',
	'Raintree Country Club',
	'Raritan Valley Country Club',
	'Red Hawk Golf and Resort',
	'Red Ledges',
	'Reserva Conchal Club',
	'Rio Mar Country Club',
	'Rio Verde Country Club',
	'Riverton Pointe Golf & Country Club',
	'Rome Downtown Racquet Club',
	'Rome Tennis Center at Berry College',
	'Salina Country Club',
	'Saltaire Yacht Club',
	'Santa Ana Country Club',
	'Santa Maria Golf Club',
	'Santa Rosa Golf & Beach Club',
	'Seminole Legacy Golf Club',
	'Sierra View Country Club',
	'Silver Creek Valley Country Club',
	'Silverado Resort',
	'Somerby Golf Club',
	'Southampton Club and Camp',
	'Southern Trace Country Club',
	'Springdale Golf Club',
	'Sterling Grove Golf & Country Club',
	'StoneTree Golf Club',
	'Sunnyside Golf & Country Club',
	'Talking Rock',
	'Terravita Golf Club',
	'The Club at ArrowCreek',
	'The Club at Castlewood',
	'The Club at Cordillera',
	'The Club at Houston Oaks',
	'The Club at Porto Cima',
	'The Clubs at St. James',
	'The Falls Club',
	'The Fountaingrove Club',
	'The Golf Club at Lansdowne',
	'The Grand Golf Club at Grand Del Mar',
	'The Peninsula Golf & Country Club',
	'The Powder Horn Golf Club',
	'The Ritz-Carlton Bacara, Santa  Barbara',
	'The Ritz-Carlton Key Biscayne',
	'The Standard Club',
	'Tiburón Golf Club',
	'Torreon Golf Club',
	'Tuckers Point Club, Bermuda',
	'Tumble Brook Country Club',
	'Tyler Athletic and Swim Club',
	'University Club of Milwaukee',
	'Valley Country Club',
	'Vanderbilt Legends Club',
	'Walden Racquet Center on Lake Conroe',
	'Wausau Country Club',
	'West Bay Club',
	'Westchester Hills Golf Club',
	'Westhaven Golf Club',
	'Westmoreland Country Club',
	'Weston Racquet Club',
	'Wickenburg Ranch Golf & Social Club',
	'Woodbridge Golf & Country Club',
	'Woodholme Country Club',
	'Yorba Linda Country Club',
];

const playClubs = [
	'Alpine Country Club (RI)',
	'Alpine Country Club (UT)',
	'Arabian Ranches',
	'Audubon Country Club',
	'Austin Country Club',
	'Bahia Beach Resort & Golf Club',
	'BallenIsles Country Club',
	'Bay Creek',
	'Bayside Resort Golf Club',
	'Beechmont Country Club',
	'Belfair',
	'Blackstone Country Club',
	'Bonita National Golf & Country Club',
	'Bookcliff Country Club',
	'Boulders Resort & Spa',
	'Boulder Ridge Golf Club',
	'Broken Top Club',
	'Calvert Crossing Golf Club',
	'Canebrake Country Club',
	'Capital Canyon Club',
	'Centurion Club',
	'Champion Hills',
	'Chateau Golf & Country Club',
	'Cimarron Hills Golf & Country Club',
	'Colonial Country Club (FL)',
	'Columbia Country Club',
	'Columbus Country Club',
	'Comanche Trace',
	'Contra Costa Country Club',
	'Country Club of Colorado',
	'Crow Canyon Country Club',
	'Crystal Lake Country Club',
	'Deering Bay Yacht & Country Club',
	'Eau Claire Golf & Country Club',
	'Echo Valley Country Club',
	'El Macero Country Club',
	'Entrada at Snow Canyon',
	'Esplanade Golf & Country Club',
	'Esplanade Golf & Country Club Lakewood Ranch',
	'Fairbanks Ranch Country Club',
	'Fairwood Golf & Country Club',
	'Fargo Country Club',
	'Fieldstone Golf Club',
	'Flagstaff Ranch Golf Club',
	'Gadsden Country Club',
	'Hammock Beach',
	'Hunting Creek Country Club',
	'Kankakee Country Club',
	'Kemper Lakes Golf Club',
	'Knollwood Country Club (MI)',
	'Lake Valley Golf Club',
	'Le Triomphe Golf & Country Club',
	'La Paloma Country Club',
	'Links at Perry Cabin',
	'Lomas Santa Fe Country Club',
	'Manchester Country Club',
	'Marin Country Club',
	'Mill Creek Country Club',
	'Naperville Country Club',
	'NorthRiver Yacht Club',
	'Ocean Club at Hutchinson Island',
	'Ocean Edge Resort & Club',
	'Oldfield Club',
	'Old Overton Club',
	'Oneida Golf & Country Club',
	'Parkland Golf & Country Club',
	'Pelican Lakes Resort and Golf',
	'PGA National Czech Republic',
	'PGA National Resort',
	'Phoenix Country Club',
	'Pine Canyon Club',
	'Pine Tree Country Club',
	'Pinetop Country Club',
	'Pronghorn',
	'RainDance National',
	'Raintree Country Club',
	'Raritan Valley Country Club',
	'Red Hawk Golf and Resort',
	'Reserva Conchal Club',
	'Rio Mar Country Club',
	'Rio Verde Country Club',
	'Riverton Pointe Golf & Country Club',
	'Salina Country Club',
	'Santa Ana Country Club',
	'Santa Maria Golf Club',
	'Santa Rosa Golf & Beach Club',
	'Seminole Legacy Golf Club',
	'Sierra View Country Club',
	'Silver Creek Valley Country Club',
	'Silverado Resort',
	'Somerby Golf Club',
	'Southern Trace Country Club',
	'Springdale Golf Club',
	'Sterling Grove Golf & Country Club',
	'StoneTree Golf Club',
	'Sunnyside Golf & Country Club',
	'Talking Rock',
	'Terravita Golf Club',
	'The Club at ArrowCreek',
	'The Club at Castlewood',
	'The Club at Cordillera',
	'The Club at Porto Cima',
	'The Club at Savannah Quarters',
	'The Clubs at St. James',
	'The Falls Club',
	'The Fountaingrove Club',
	'The Golf Club at Lansdowne',
	'The Grand Golf Club at Grand Del Mar',
	'The Peninsula Golf & Country Club',
	'The Powder Horn Golf Club',
	'The Ritz-Carlton Key Biscayne',
	'The Standard Club',
	'Tiburón Golf Club',
	'Torreon Golf Club',
	'Tumble Brook Country Club',
	'Tyler Athletic and Swim Club',
	'University Club of Milwaukee',
	'Valley Country Club',
	'Vanderbilt Legends Club',
	'Wausau Country Club',
	'West Bay Club',
	'Westchester Hills Golf Club',
	'Westhaven Golf Club',
	'Westmoreland Country Club',
	'Wickenburg Ranch Golf & Social Club',
	'Woodbridge Golf & Country Club',
	'Woodholme Country Club',
	'Yorba Linda Country Club',
];
